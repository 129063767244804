
.logo-pic {
    width: 54%;
    margin-right: 55%;
}


.text-of-logo {
    color: rgb(0, 0, 0);
    font-size: 75%;
    text-decoration: none;
    font-family: 'Raleway';
    font-weight: bold;
}

.no-underline{
  text-decoration: none;
}

.profile-pic-white {
  margin-left: 85%;
}

.profile-pic-white:hover {
  background-color: rgb(241, 240, 240) !important;
}






.black {   
    color: black;
    font-size: 80%;
    position: relative;
}


.black::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border: 1px solid transparent;
  background-color: rgb(241, 240, 240);
  opacity: 0; 
  pointer-events: none; 
  z-index: -1; 
  transition: opacity 0.3s ease;
}

.black:hover::before {
  opacity: 1;
}


.black-service-btn {  
  font-size: 82%;
}

/* white_navbar.css */
/* .navbar-line {
  border-bottom: 2px solid rgba(66, 63, 63, 0.2);
} */




 .dropdown {
  position: relative;
  display: inline-block;
  color: rgb(0, 0, 0);
  background-color: rgb(255, 255, 255);
  border-radius: 0 !important;
}

.dropdown:hover{
  background-color: rgb(241, 240, 240);
}
  
  .dropdown-text{
    text-decoration: none;
    color: black;
    font-size: small;
    margin-left: 10%;
  }

  .drop-menu{
    font-family: 'Raleway';
    transform: translateX(-65%);
    border-radius: 0;
    left: 1%;
  }


  .dropdown-menu .dropdown-text:hover {
    background-color: #f2f2f2;
    color: rgb(5, 5, 5);
    font-family: 'Raleway';
  }



  .white-cyan-bar {
    position: relative;
  }
  
  .white-cyan-bar::after {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #00e1ff; 
    transform: scaleX(0);
  }
  
  .white-cyan-bar:hover::after {
    bottom: -65% !important;
    transform: scaleX(1);
    opacity: 1;
  }


@media (max-width: 1400px) {

  .logo-pic {
    margin-left: 35%;
}


.text-of-logo {
  margin-left: 12%;
  font-size: 70%;
  font-weight: bold;
  font-family: 'Raleway' !important;
}

.profile-pic-white {
  margin-left: 65%;
}

.menu-class {
  margin-left: 55%;
  margin-top: 5%;
}

.menu-class-drop{
  margin-left: 65%;
}

.services-class-drop{
  margin-left: 7%;
}


.dropbtn {
  margin-left: -15%;
}



/* Style the dropdown content (hidden by default) */
.dropdown-content {
  width: 250px !important; 
  transform: translateX(-35%) !important;
}

/* Style the columns in the service table */
.column {
  width: 100% !important;
  padding: 5% !important;
}

  }



  /* Style the dropdown button */
.dropbtn {
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  padding: 8px;
  border: none;
  cursor: pointer;
  font-size: 80%;

  position: relative;
}

.dropbtn:hover {
  background-color: #ffffff;
  color:#00e1ff;
  padding: 8px;
  border: none;
  cursor: pointer;
  font-size: 80%;
}


/* Style the dropdown content (hidden by default) */
.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  width: 1100%; 
  z-index: 10;
  transform: translateX(-65%);
  text-align: left;
  top: 40px; 
  font-family: 'Raleway';
  border: 1px solid rgb(194, 189, 189);
  box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.2);
}



/* Style the service table */
.service-table {
  padding-right: 1.5%;
  padding-left: 1.5%;
  padding-bottom: 1.5%;
}

/* Style the rows and columns in the service table */
.row::after {
  content: "";
  clear: both;
  display: table;
}

.column {
  width: 33.33%;
  float: left;
  padding: 2%;
}

/* Style the headings in each column */
.column h3 {
  font-size: 80% !important;
  font-weight: bold;
  color: grey;
  border-bottom: 1px solid #ddd; /* Solid line under the heading */
  padding-bottom: 5%; /* Adjust the spacing between the line and text */
  padding-left: 3%;
}



/* Style the list items in each column */
.column ul {
  list-style-type: none;
  padding: 0;
}

/* Style the links inside the dropdown */
.column ul li a {
  padding-left: 3%;
  padding-top: 1%;
  margin-bottom: 5%;
  text-decoration: none;
  display: block;
  font-size: 80%;
  color: rgb(13, 13, 149);
}

/* Change the link color on hover */
.column ul li a:hover {
  background-color: #ddd;
}

/* Show the dropdown when the button is hovered or clicked */
.dropdown:hover .dropdown-content {
  display: block;
}
