
 /* section 1 */

 .Page-fellow {                                                   
    background-image: url(../images/cle_banner.png) ;         
    backdrop-filter: blur(10px) !important;
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    height: 62dvh ;
    background-size: cover ;
    background-position: center ;
    
  }
  
    .banner-title-fellow                          
  {
      color: white;
      font-size: 310%;
      font-weight: bold;
  }
  
  
  .banner-tagline-fellow                       
  {
      font-size: 250%;
      color: white;
      font-family: 'Raleway', fantasy;
      margin-top: 0px !important;
  }


  


  /* section 2 */


  .center-heading-fellow {
    font-family: 'Raleway';
    text-align: center !important;
    color: black ;
    font-size: 215%;
    margin-top: 5%;
    margin-bottom: 13% ; 
    display: inline-block;
}


.center-heading-fellow:after {
  content: "";
  display: block;
  bottom: 5%; 
  left: 44%;
  right: 44%;
  border-bottom: 2px solid rgb(47, 199, 238);
  padding-top: 20px;

  margin: 0 auto;
  
}



.call-proposal {
    font-size: 35px;
    font-family: 'Raleway';
    margin-top: -10%;
    margin-bottom: 2%;
    color: rgb(58, 56, 56);
}


.paragraph_text-fellow {
    color: black;
    font-family: 'Raleway';
    text-align: left;
    font-size: 90%;
}


.fellow-table{
    font-family: 'Raleway';
    font-size: 95%;
    border-collapse: collapse;
    margin-left: 5%;
    margin-bottom: 2%;
    text-align: center;
    width: 85%;
}

.fellow-table th {
    background: #1b92c0;
    color: #FFF;
  }
  
  .fellow-table th, .fellow-table td {
    border: 1px solid #CCC;
    padding: 6px; 
  }
  



.fellow-heading {
    font-family: 'Raleway';
    text-align: left;
    color: #337ab7;
    padding-top: 2%;
    padding-bottom: 1%;
    text-align: left;
    font-size: 190%;
}

.fellow-details {
    font-family: 'Raleway';
    text-align: left;
    font-size: 90%;
    color: black;
}



@media (max-width: 1400px) {

    .Page-fellow { 
        width: 100%;
    }

    .banner-title-fellow {
        font-size: 10vw; 
    }
    .banner-tagline-fellow {
        font-size: 6vw; 
    }





    .center-heading-fellow {
        font-size: 200%;
        margin-top: 5%;
        margin-bottom: 13% ; 
    }


    .center-heading-fellow:after {
        content: "";
        display: block;
        bottom: 5%;
        width: 90%;
        
    }


    .call-proposal {
        font-size: 160% !important;
        margin-bottom: 5% !important;
    }



}
