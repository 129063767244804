
.admin_logo {
    font-size: 150%;
    font-weight: bold;
    color: #000080;
    margin-left: 13%;
    font-family: "Raleway";
}



.admin_user_name {
    text-align: right;
    margin-right: 10%;
    margin-top: -1%;
    font-family: "Raleway";

}


.admin-drop-menu {
    border-radius: 0 !important;
    font-family: "Raleway";
    font-size: 85%;
}

.admin-drop-menu:hover {
    background-color: rgb(219, 216, 216);
}
