
  .forget-page {                                               
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    padding: 10%;
    background-size: contain ;
    background-position: cover ;

    font-family: 'Raleway';
    
  }


  .forget-heading {
    position: relative;
    position: absolute;
    margin-top: -9%;
    left: 0%;
    text-align: center;
    font-family: 'Raleway-Medium';
    font-size: 30px;
    display: inline-block;
    font-weight: lighter;
  }

  .forget-heading:after {
    content: "";
    display: block;
    position: absolute;

    height: 2px;
    left: 40%;
    width: 20%;

    margin-top: 1%;
    background-color: rgb(31, 223, 223);
  }

  
.inputField-forget {
  border-radius: 0px 0px 0px 0px;
  width: 65%;
  margin: 3% auto;
  margin-top: 14%;

  resize: none;
  border-radius: 0px !important;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  background: linear-gradient(to right, #00e1ff 20%, #00e1ff 80%) 0 100% / 100% 2px no-repeat, linear-gradient(to right, transparent 30%,transparent 70%) 0 0 / 100% 2px no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 0 0 / 2px 100% no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 100% 0 / 2px 100% no-repeat white;
  font-size: 1em;
}


.buttonField-forget {
  border: 1px solid;
  border-radius: 0px 0px 0px 0px;
  border-width: 1px;
  width: 65%;
  height: 20%;
  border-color: #37d9ef;
  margin-top: 6%;

  font-family: 'Raleway';
}

.buttonField-forget:hover {
  border: 1px solid;
  border-radius: 0px 0px 0px 0px;
  border-width: 1px;
  width: 65%;
  height: 20%;
  border-color: #37d9ef;
  margin-top: 6%;
}



.form-border-forget {
  border: 1.5px solid #37d9ef;
  border-radius: 3%;

  width: 60%; /* add this line to shrink the form */
  min-height: 100%;
  height: 300px;
  margin-top: 3%;

  z-index: 2; 
}


.mail-send-msg {
  font-size:x-large;
  background-color: rgb(217, 215, 215);
  color: black;
  border: 1.5px solid #d9e0e0;
  border-radius: 0.5%;

  padding: 5%;
  width: 70%; /* add this line to shrink the form */
  min-height: 100%;
  margin-top: 3%;

  z-index: 2; 

}


@media (max-width: 1400px) {

  .forget-page {             
    min-height: 100%;
    padding: 0.2%;
    margin-bottom: 15%;
    margin-top: 15%;
  }


  .forget-heading {
    margin-top: -20%;
    font-family: 'Raleway-Medium';
    font-size: 19.5px;
    font-weight: lighter;
  }

  .forget-heading:after {
    left: 35%;
    width: 30%;
    margin-top: 5%;
 }

  
.inputField-forget {
  width: 100%;
  margin: 3% auto;
  margin-top: 14%;
  font-size: 0.85em;
}


.buttonField-forget {
  width: 100%;
  height: 18%;
  font-size: 0.85em;
}

.buttonField-forget:hover {
  width: 65%;
  height: 20%;
  margin-top: 6%;
  font-size: 0.85em;
}



.form-border-forget {
  width: 100%;
  height: 210px; 
  margin-top: 15%;
}
}