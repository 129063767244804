.admin-users-page {
    width: 100%;
    height: 100%;
    font-family: "Raleway";
}

#searchBox {
    background-image: url(../icons/navbar/searchicon.png);
    background-position: 10px 10px;
    background-repeat: no-repeat;
    width: 96%;
    font-size: 16px;
    padding: 12px 20px 12px 40px;
    border: 1px solid #ddd;
}








/* section 2 */
.users-management {
    height: 100%;
    margin-top: 1%;
    margin-bottom: 5%;
}

.container-admin-users {
    padding-right: 1%;
    padding-left: 1%;
    width: 100%;
    display: table;
    content: " ";
}

.admin-users-panel {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}

.admin-users-panel-body {
    padding: 1%;
}



.admin-users-table {
    width: 100%;
    border: 1px solid #ddd;
    background-color: transparent;
}

.admin-users-table>caption+thead>tr:first-child>th, 
.admin-users-table>colgroup+thead>tr:first-child>th, 
.admin-users-table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.admin-users-table thead > tr > th {
    border-bottom: 2px solid;
    border-color: #ddd;
    text-align: left;
    font-family: 'Raleway';
    font-size: 90%;
}

.admin-users-table>tbody>tr>td  {
    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;
}

.admin-users-table>tbody>tr>td, 
.admin-users-table>tfoot>tr>td, 
.admin-users-table>tfoot>tr>th, 
.admin-users-table>thead>tr>td, 
.admin-users-table>thead>tr>th {
    padding: 7px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.admin-users-table thead th:first-child,
.admin-users-table tbody td:first-child {
    width: 30%;
}

.admin-users-table thead th:nth-child(2),
.admin-users-table tbody td:nth-child(2) {
    width: 40%;
}

.admin-users-table thead th:nth-child(3),
.admin-users-table tbody td:nth-child(3) {
    width: 15%;
}

.admin-users-table thead th:nth-child(4),
.admin-users-table tbody td:nth-child(4) {
    width: 15%;
}





/* add balance model section */
.users-form-properties{
    font-family: 'Raleway';
}

.users-form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.users-form-label {
    font-size: 90%;
    font-weight: bold;
    flex: 1;
}

.users-form-box {
    font-size: small;
    margin-right: 10%;
    flex: 1;
}

.btn-font {
    font-family: 'Raleway';
}

.user-balance-btn {
    background-color: #5cb85c;
}





/* send mail model section */
.users-mail-form-group {
    display: flex;
    align-items: center;
}

.users-mail-form-label {
    font-size: 90%;
    font-weight: bold;
    flex: 0.4;
    margin-left: 10%;
}

.users-mail-form-box {
    font-size: small;
    flex: 1;
    margin-right: 1%;
}






/* View users history summary model */
.summary-container {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 2%;
    padding-bottom: 7%;
}

.user-summary-center-heading {
    font-family: 'Raleway';
    text-align: center;
    padding-top: 1%;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 220%;
}

.user-summary-center-heading:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 20%;
    padding-top: 2%;
    border-bottom: 2px solid #00e1ff;
}

.summary-panel {
    border: 1px solid #bce8f1;
    border-radius: 4px;
}

.summary-heading {
    font-family: 'Raleway';
    background-color:#d9edf7;
    border: 1px solid #bce8f1;
    color: #31708f;
    text-align: left;
    padding: 10px 15px;
    font-size: 90%;
}

.summary-panel-body {
    padding: 15px;
}

.summary-table {
    width: 100%;
    background-color: transparent;
}

.summary-table>caption+thead>tr:first-child>th, 
.summary-table>colgroup+thead>tr:first-child>th, 
.summary-table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.summary-table thead > tr > th {
    border-bottom: 2px solid;
    border-color: #ddd;
    text-align: left;
    font-family: 'Raleway';
    font-size: 90%;
}

.summary-table>tbody>tr>td  {
    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;
}

.summary-table>tbody>tr>td, 
.summary-table>tfoot>tr>td, 
.summary-table>tfoot>tr>th, 
.summary-table>thead>tr>td, 
.summary-table>thead>tr>th {
    padding: 7px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.summary-pagination-centered {
    text-align: center !important; 
}




/* view user costing model */

.admin-user-costing-view-page {
   min-height: 100% !important;
}