@font-face 
{
	font-family: 'Raleway';
	src: url('../fonts/Raleway-Regular.ttf');
}

@font-face 
{
	font-family: 'Raleway-Medium';
	src: url('../fonts/Raleway-Medium.ttf');
}

@font-face {
	font-family: 'Raleway-Light';
	src: url('../fonts/Raleway-Light.ttf');
}

@font-face {
	font-family: 'ProximaBold-Condensed';
	src: url('../fonts/Mark\ Simonson\ -\ Proxima\ Nova\ Alt\ Bold.otf');
}