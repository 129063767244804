
.first-box-tts {
    margin-right: 8%;
    margin-left: 8%;
}

.left-services {
    font-family: 'Raleway';
    color: #337ab7;
    text-align: left;
    padding-top: 1%;
    font-weight: bold;
    font-size: 14px;
    margin-left: 3%;

}

.left-services:hover{
    text-decoration: underline;
}


.container-well {
    background-color: #eee !important;
    border: 1px solid #e3e3e3;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}

.tts-heading {
    font-family: 'Raleway';
    font-size: 24px;
    padding-top: 2%;
    padding-bottom: 2%;
}


.fields-format {
    position: relative;
    width: 20%;
    float: left;
}


.left-label-tts {             
    margin-bottom: 2%;
    float: left;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }


#tts-voice{
    font-family: 'Raleway';
    color: grey;
    margin-right: 6%;
    margin-bottom: 2%;
    width: 80%;
}

.slider {
    margin-right: 6%;
    width: 80%;
}



.tts-textarea {             
    margin-bottom: 2%;
    width: 78%;
    font-size: 16px;
    direction: rtl;
  }


.tts-button {
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;
    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    margin-left: -45% !important;
    font-size: 90%;
}


.tts-lower-btn{
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;
    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    font-size: 90%;
    margin-top: -5%;
    margin-right: 5% !important;
}


@media (max-width: 1400px){

    .first-box-tts {
        margin-left: 1%;
    }

    .left-services {
        padding-top: 1%;
        padding-bottom: 2%;
        font-size: 14px;
    }

    .left-services:hover{
        text-decoration: underline;
    }


    .container-well {
        background-color: #eee !important;
        border: 1px solid #e3e3e3;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        width: 110%;
        margin-left: -1% !important;
    }


    .fields-format {
        width: 20%;
    }

    .left-label-tts {   
        margin-left: -15%;          
        padding-bottom: 2%;
    }

    #tts-voice{
        color: grey;
        width: 500%;
        padding-bottom: 2% !important;
    }
    
    .slider {
        width: 500%;
    }


    .tts-textarea {               
        margin-bottom: 10%;
        width: 100%;
        font-size: 16px;
        direction: rtl;
    }

    .tts-button {
        margin-right: 25%;
    }

    .btn1 {
        margin-left: 30%;
    }

    .tts-lower-btn {
        margin-top: -16% !important;
    }

    
    /* this one is for male tts */
    .gap {   
        margin-top: 5% !important;
    }

}