
  .confirm-page {                                               
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    padding: 10%;
    background-size: contain ;
    background-position: cover ;

    font-family: 'Raleway';
    
  }



.mail-send-msg {
  font-size:x-large;
  background-color: rgb(217, 215, 215);
  color: black;
  border: 1.5px solid #d9e0e0;
  border-radius: 0.5%;

  padding: 5%;
  width: 70%;
  min-height: 100%;
  margin-top: 3%;

  z-index: 2; 

}


@media (max-width: 1400px) {

  .confirm-page {             
    min-height: 100%;
    padding: 0.2%;
    margin-bottom: 15%;
    margin-top: 15%;
  }

}