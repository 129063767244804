.Page-privacy {          
    min-height: 100%;
  }



.privacy {
  text-align: center;
  font-family: 'Raleway';
  font-size: 34px;
  font-weight: lighter;

  margin-left: 45%;
  margin-top: 8%;
  color: black;

}



.privacy::after {
  content: "";
  display: block;
  position:relative;

  height: 1.5px;
  width: 40%;

  margin-left: 30%;
  margin-top: 3%;
  background-color: rgb(31, 223, 223);
}



.container-privacy {
    margin-bottom: 5%;
    margin-left: 10%;
    margin-right: 10%;

    font-family: 'Raleway';
    display: inline-block;
    font-weight: lighter;
}


pe {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;

  font-size: 14px;
}



.bo, strong {
  font-weight: 700;
  font-size: 15px;
}

h3 {
  font-size: 24px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
}


@media (max-width: 1400px) {

  .privacy {
    position: relative;
    font-family: 'Raleway';
    font-size: 30px;
    font-weight: lighter;
  
    margin-top: 8%;
    margin-left: 0 !important;
    color: black;
  
  }
  
  .privacy::after {
    content: "";
    display: block;
    position: relative;
  
    height: 1.5px;
    width: 50%;
  
    margin-left: 25%;
    background-color: rgb(31, 223, 223);
  }


  .container-privacy {
    margin-bottom: 5%;
    margin-left: 3%;
    margin-right: 3%;
}


pe {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  font-size: small !important;
}



.bo, strong {
  font-weight: 700;
  font-size: 13px !important;
}

h3 {
  font-size: 17px !important;
  color: black !important;
}

}