
.black-logo-pic {
    width: 50%;
    margin-top: 5%;
    margin-bottom: 3%;
    margin-right: 55%;
}


.black-text-of-logo {
    color: rgb(250, 250, 250);
    font-size: 78%;
    text-decoration: none;
    font-weight: bold;
}



.dashboard-get-started {
  color: aqua;
  background-color: black;
  border-radius: 0;
  border: none;
}

.dashboard-get-started:hover{
  color: aqua;
  background-color: black;
 }

 .dashboard-get-started:focus
 {
  color: aqua;
  background-color: black;
  outline: none; 
}




 #balance-text {
  border: 1px;
  border-style: solid;
  border-color: #00e1ff;
  width: 100%;
  height: 60%;

  font-family: 'Raleway';
  font-size: 90%;
  text-align: left;

  margin-top: -8%;
}



.drop-menu-dash
{
  font-family: 'Raleway';
  border-radius: 0;
  left: 1%;
}


.dropdown-menu-dash .dropdown-text:hover {
  background-color: #f2f2f2;
  color: rgb(5, 5, 5);
  font-family: 'Raleway';
}



@media (max-width: 1400px) {

  .menu-class-black-dash {
    margin-top: 5%;
  }

  
  .services-class-black-dash-drop{
    margin-left: 7%;
  }


  .dash-black {
    background-color: black;
  }


  .dash-link {
    margin-left: 10% !important;
  }


  .black-navbar-menu-center {
    margin-left: 35% !important;
  }

}
