
.history-page {                                               
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    padding: 10%;
    background-size: contain ;
    background-position: cover ;

    font-family: 'Raleway';
    
  }



  .history-box {
    font-size:x-large;
    background-color: rgb(234, 230, 230);
    color: black;
    border: 1.5px solid #d9e0e0;
    border-radius: 0.5%;
  
    padding: 2%;
    width: 100%;
    min-height: 100%;
  
    z-index: 2; 
  
  }
  

.history-content-heading {
    font-size: 60%;
    text-align: left;
    font-weight: 600;
    padding: 1%;
}

.history-content-detail {
  font-size: 60%;
  text-align: left;
  padding: 1%;

}
  
  @media (max-width: 1400px) {
  
    .history-page {             
      min-height: 100%;
      padding: 0.2%;
      margin-bottom: 15%;
      margin-top: 15%;
    }
  
  }