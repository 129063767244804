
.first-box-access-token {
    margin-right: 8%;
    margin-left: 8%;
}

.access-container-well {
    background-color: #eee !important;
    min-height: 20px;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

    height: auto;
    height: 245px;
}


.formating {
    font-family: 'Raleway';
}


.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;

    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;

    margin-right: 30%;
}


.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}


.access-token-page{
    font-weight: bold;
}

.access-token-button {
    font-family: 'Raleway';
    width: 15%;
    background-color: #337ab7;
    border-color: #2e6da4;

    margin-right: 34%;
}

.access-token-button:hover {
    font-family: 'Raleway';
    width: 15%;
    background-color: #337ab7;
    border-color: #2e6da4;

    margin-right: 34%;
}


@media (max-width: 1400px){ 

    .first-box-access-token {
        margin-left: -6%;
    }
    
    .access-container-well {
        height: 100% !important;
        width: 102%;
    }
    
    
    .alert {
        padding: 15px;
        margin-bottom: 20px;
        margin-right: 2%;
        width: 100%;
    }
    
    .access-token-page{
        margin-left: 1%;
        float: left;
    }
    
    .access-token-button {
        width: 35%;
        margin-left: -30%;
    }
    
    .access-token-button:hover {
        width: 35%;
        margin-left: -30%;
    }

}