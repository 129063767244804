.first-box-spell {
    margin-right: 8%;
    margin-left: 8%;
}


.spell-container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 560px !important;
}


.spell-fields-format {
    font-family: 'Raleway';
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}



.left-label-spell {             
    padding-right: 25%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }


#spell-voice{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}



.spell-textarea {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;
    font-size: 16px;
    direction: rtl;

    width: 800px !important;
    height: 120px;
  }


.spell-button {
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;

    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    margin-right: 10px !important;
    margin-left: 15px !important;

    font-size: 90%;
}


.spell-label {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 86%;
}




#sgstDiv {
  width: 19%;
  margin-left: 3%;
  float: left;
  margin-top: -19.5%;
}

.sugguest-word-label {
  float: left;
}

#selectedWrod {
  margin-right: 20%;
}





.flex-container-button-spell {
    display: flex;
    flex-wrap: wrap;
}

.middle-btn-spell{
  margin-left: -62%;
}

.spell-bottom-padding {
  padding-bottom: 5%;
}



@media (max-width: 1400px){ 
    
    .spell-container-well {
        width: 99.5vw;
        height: 100% !important;
        margin-left: -12.3% !important;
        box-sizing: border-box;
    }
    
    
    .left-label-spell {          
        font-family: 'Raleway';
        font-size: 90%;
        font-weight: 600;
        margin-left: -30%;
      }


    .flex-container-button-spell {
        width: 100%;
    }

    #spell-voice{
      margin-top: 1%;
      margin-left: -20%;
    }

    .spell-label {
      margin-left: 4%;
      font-family: 'Raleway';
      font-weight: bold;
   }
    
    .spell-textarea {    
      margin-left: 4%;
      width: 98% !important;
      direction: rtl;
    }


    .spell-container-well-suggestions{
      background-color: #eee !important;
      padding: 19px;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
      
      border-top: none;
      width: 99.5vw !important;
      height: 100% !important;
      margin-top: 5.5% !important;
      margin-left: -5.5% !important;
      margin-bottom: 5% !important;
    }

    #sgstDiv {
      width: 100%;
      margin-left: 0%;
      float: left;
      margin-top: -17%;
    }
      
    .sugguest-word-label {
      float: none !important;
    }
    
    #selectedWrod {
      margin-right: 20%;
    }
    
    .middle-btn-spell{
        margin-top: 5%;
        margin-left: 0%;
    }

    .spell-bottom-padding{
      padding-bottom: 80% !important;
    }

}