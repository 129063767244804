.key-first-box {
    margin-left: 8%;
    margin-right: 8%;
}


.key-container-well {
    background-color: #eee !important;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 560px;
}

.words-label {
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 85%;
    margin-left: -30%;
    margin-right: 15%;
}


.keywords-number {
    width: 15%;
}


.key-textarea {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;
    width: 200%;
    height: 49%;
    font-size: 16px;
    direction: rtl;
  }


.key-label-input {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 73%;
}

.key-label-output {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 79%;
}

.key-btn {
    margin-right: 37%;
}

#key-voice{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}

.left-label-key {             
    padding-right: 30%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }

@media (max-width: 1400px){ 
    
    .key-container-well {
        background-color: #eee !important;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        width: 99.9vw;
        height: 580px !important;
        margin-left: -12.5% !important;
        box-sizing: border-box;
    }


      .text-label {
        margin-left: 2%;
        font-weight: bold;
    }
    
    .key-textarea {    
        margin-left: 1%;
        width: 90%;
        font-size: 16px;
        height: 45%;
        direction: rtl;
      }

    .key-label-input {
        margin-right: 72%;
    }
    
    .key-label-output {
        margin-right: 77%;
    }
    
    .keywords-number {
        margin-right: 5%;
    }

    .words-label {
        margin-left: -20%;
    }

    .key-btn {
        margin-right: 30%;
    }

    #key-voice{
        margin-top: 2%;
        margin-left: -17%;
    }

    .left-label-key {   
        font-weight: 600;
        margin-left: -26%;
    }
}