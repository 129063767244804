
.first-box-user-info {
    margin-right: 8%;
    margin-left: 8%;
    font-family: 'Raleway';
}

.user-info-container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    height: auto;
}


.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert {
    border: 1px solid transparent;
    border-radius: 4px;

    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;

    padding: 15px;
    margin-bottom: 20px;
    margin-right: 30%;
}


.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}


.user-info-page{
    font-weight: bold;
    color: #343333;
    font-size: 90%;
    margin-top: 1%;
    margin-left: -4%;
    text-align: right;
}


.user-info-button {
    width: 11%;
    background-color: #337ab7;
    border-color: #2e6da4;
    margin-right: 37%;
    font-size: 90%;
}


.user-info-button:hover {
    width: 11%;
    background-color: #337ab7;
    border-color: #2e6da4;
    margin-right: 37%;
    font-size: 90%;
}


.label-active {
    color: #65ac76;
  }
 
  
#user-info-input {
    margin-left: -45% !important;
}

  .user-info-input-active {
    color: #65ac76 !important;
    border: 1px solid #65ac76 !important;
    background-color: white;
  }
  
  .user-info-input-default {
    border: 1px solid #ccc;
    background-color: #fff;
  }
  


@media (max-width: 1400px){ 

    .first-box-user-info {
        margin-left: -5%;
    }
    
    .user-info-container-well {
        height: 100% !important;
        width: 102%;
    }
    
    
    .alert {
        padding: 15px;
        margin-bottom: 20px;
        margin-right: 2%;
        width: 100%;
    }

    #user-info-input {
        margin-left: 0% !important;
    }
    
    .user-info-page{
        margin-left: 0%;
        float: left;
    }
    
    .user-info-button {
        width: 45%;
        margin-left: 0%;
    }
    
    .user-info-button:hover {
        width: 35%;
        margin-left: 0%;
    }

}