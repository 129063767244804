.Page-terms {             
    min-height: 100%;
  }



.terms {
  position: absolute;
  font-family: 'Raleway';
  font-size: 34px;
  display: inline-block;
  font-weight: lighter;
  color: black;

  margin-left: 5%;
  margin-top: 8%;

}



.terms::after {
  content: "";
  display: block;
  position: absolute;

  height: 1.5px;
  width: 95%;

  margin-top: 5%;
  margin-left: 3%;
  background-color: rgb(31, 223, 223);
}



.container-terms {
    margin-top: 13%;
    margin-bottom: 7%;
    margin-left: 10%;
    margin-right: 10%;

    font-family: 'Raleway';
    display: inline-block;
    font-weight: lighter;
}


pe {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;

  font-size: 14px;
}



.bo, strong {
  font-weight: 700;
  font-size: 15px;
}

h3 {
  font-size: 24px;
}

ul {
  display: block;
  list-style-type: disc;
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
}





@media (max-width: 1400px) {


  .terms {
    position: relative;
    font-family: 'Raleway';
    font-size: 30px;
    font-weight: lighter;
  
    margin-top: 8%;
    margin-left: 0 !important;
    color: black;
  
  }
  
  .terms::after {
    content: "";
    display: block;
    position: relative;
  
    height: 1.5px;
    width: 50%;
  
    margin-left: 25%;
    background-color: rgb(31, 223, 223);
  }


  .container-terms {
    margin-bottom: 5%;
    margin-left: 3%;
    margin-right: 3%;
    margin-top: 0 !important;
}


pe {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  font-size: small !important;
}



.bo, strong {
  font-weight: 700;
  font-size: 13px !important;
}

h3 {
  font-size: 17px !important;
  color: black !important;
}
}