

.image-services-content {
    padding-top: 4%;
    padding-left: 15%;
    padding-right: 14%;
}



.static-image_services-center-heading {
    font-family: 'Raleway';
    text-align: center !important;
    color: black;
    font-size: 215%;
    margin-top: 5%;
    display: inline-block;
}


.static-image_services-center-heading:after {
    content: "";
    display: block;
    bottom: 5%;
    left: 44%;
    right: 44%;
    border-bottom: 2px solid rgb(47, 199, 238);
    padding-top: 20px;
    margin: 0 auto;
    width: 50%;
    
  }


.image_services_title_box {
    font-family: 'Raleway';
    text-align: left;
}

.image_services_title {
    color: #337ab7;
    font-size: 97%;
}

.image_services_title:hover {
    color: #18568d !important;
    text-decoration: underline;
}

.image_services_text {
    font-size: 90%;
}

@media (max-width: 800px){  

    .image-services-content {
        padding-left: 10%;
        padding-right: 10%;
    }

    .static-image_services-center-heading {
        font-size: 150% ;
    }

    .image_services_title {
        font-size: large;
    }
    
    .image_services_title:hover {
        font-size: large;
    }

    .image_services_text {
        font-size: 75%;
    }


}