.content {
    background-color: #ffffff;
    height: 100%;
    flex: 1 0 auto;
}


#explore-services-container {
    background: #1f1f23;

    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 1%;
    padding-top: 4%;
    width: 100%;

    margin-left: auto;
    margin-right: auto;
}



.home-center-heading {
    font-family: 'Raleway';
    text-align: center;
    margin-left: 5%;
    margin-right: 5%;
    font-size: 220%;
}

.home-center-heading:after {
    content: "";
    display: block;
    margin: 0 auto;
    width: 20%;
    padding-top: 2%;
    border-bottom: 2px solid #00e1ff;
}


.icons-container {
    margin-top: 15%;
    margin-bottom: 7%;

    margin-right: -4%;
    margin-left:  -4%;
}



@media (max-width: 1400px){



.img-response{
    width: 100%;
    height: auto;
}

.services-image-text {
    color: white;
    font-size: small;
    margin-top: 4%;
    filter: none;
}

.services-image-text:hover{
    text-decoration: underline;
}


a {
    text-decoration: none;
    }


    .home-center-heading {
        margin-left: 5%;
        margin-right: 5%;
        font-size: 200%;
    }
    
    .home-center-heading:after {
        width: 50%;
    }

}


.img-response{
    width: 50%;
    height: auto;
}

.services-image-text {
    color: white;
    font-size: small;
    margin-top: 4%;
    filter: none;

}

.services-image-text:hover{
    text-decoration: underline;
}


a {
    text-decoration: none;
    }




/* section 2 */


@media (max-width: 1400px){

    .container {
        width: 100% !important;
    }

    .panel-settings {
        padding: 5%;
        padding-left: 5%;
        padding-right: 5%;
        overflow-x: auto;
        overflow-y: auto;
    }

    .pagination {
        margin: 1% 0 !important;
        padding-left: 1% !important;
    }

    .pagination>li>a, .pagination>li>span {
        position: relative;
        float: left;
        padding: 5px 11px !important;
        margin-left: -9px !important;
    }

}



.container {
    width: 65%;
    height: 100%;
}


.panel-settings {
    padding: 5%;
    border: 2px;
    border-style: solid;
    border-color: #00e1ff;

    margin-bottom: 5%;
    margin-top: 8%;
    padding-left: 5%;
    padding-right: 5%;
}



.table {
    width: 100%;
    max-width: 100%;
    margin-bottom: 4.5%;
    margin-top: 6%;

    background-color: transparent;
    border-collapse: collapse;

    min-height: .01%;
}




.table>caption+thead>tr:first-child>th, 
.table>colgroup+thead>tr:first-child>th, 
.table>thead:first-child>tr:first-child>th {
    border-top: 0;
}


.table thead > tr > th {
    border-bottom: 4px solid #00e1ff;
    text-align: left;
    font-family: 'Raleway';
    font-size: 90%;

}

.table>tbody>tr>td  {
    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;

}


.table>tbody>tr>td, 
.table>tfoot>tr>td, 
.table>tfoot>tr>th, 
.table>thead>tr>td, 
.table>thead>tr>th {
    padding: 7px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}


.job-ref:hover  {
    color: #337ab7;
    text-decoration: underline;
}


.w-cost {
    margin-right: 10%;
    padding-right: 20%;
}










.form-group {
    margin-bottom: 5%;
}

.pagination-centered {
    text-align: center !important; 
}


.pagination {
    margin: 4% 0;
    border-radius: 4px;
    padding-left: 20%;
    padding-bottom: -1%;
    font-family: 'Raleway';
}

.disabled {
    pointer-events: none;
    opacity: 0.4;
}



.pagination>li:first-child>a,
.pagination>li:last-child>a,
.pagination>li:first-child>span {
    border-top-left-radius: 4px;
    border-bottom-left-radius: 4px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
}

.pagination-centered ul li a {
    color: #00e1ff;
}

.pagination>li>a, .pagination>li>span {
    position: relative;
    float: left;
    padding: 5px 11px;
    margin-left: -2px;
    line-height: 1.42857143;
    text-decoration: none;
    background-color: #fff;
    border: 1px solid #ddd;
}


