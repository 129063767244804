.admin-page {
    width: 100%;
    height: 100%;
    font-family: "Raleway";
}

.breadcrumb {
    padding-top: 1px;
    padding-bottom: 1px;
    list-style: none;
    background-color: #f5f5f5;
}


.admin-home {
    margin-left: -10%;
    margin-top: 1%;
    margin-bottom: -1%;
}

.admin-home-text{
    font-family: 'Raleway';
    font-size: 90%;
    color: #337ab7;
}

.admin-home-text:hover {
    color: #0d0449 !important;
    text-decoration: underline;
}


#main {
    margin-top: 4%;
    margin-bottom: 18%;
}



.w3-center {
    text-align: center !important;
}


.flex-container-admin {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}


.w3-white, .w3-hover-white:hover {
    color: #000 !important;
    background-color: #fff !important;
}

.w3-card-4 {
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19) !important;
}
.w3-margin {
    margin: 1.5% !important;
}
.w3-round-large {
    border-radius: 4% !important;
}
.service-card {
    height: 200px;
    width: 200px;
    align-content: center;
    align-items: center;
    font-size: 95%;
}

.w3-container {
    padding: 0.01em 16px;
}

.w3-padding-16 {
    padding-top: 6% ;
    padding-bottom: 6% ;
}


.admin-img1 {
    width: 90%; 
}

.admin-img2 {
    margin-top: 20%;
    width: 110%; 
    height: 70%;
}