.text-first-box {
    margin-left: 8%;
    margin-right: 8%;
}


.sum-container-well {
    background-color: #eee !important;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 550px;
}


.left-label-text {             
    padding-right: 20%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }


.sentences-label {
    font-family: 'Raleway';
    font-weight: bold;
    font-size: 85%;
    margin-right: 10%;
}


.text-textarea {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;

    width: 280%;
    height: 49%;
    font-size: 16px;
    direction: rtl;
  }


.text-label-input {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 65%;
}

.text-label-output {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 74%;
}

.text-btn{
    margin-right: 11%;
}

#text-voice{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}

#text-voice-2{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}


@media (max-width: 1400px){ 
    
    .sum-container-well {
        background-color: #eee !important;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        width: 99.99vw;
        height: 600px !important;
        margin-left: -12.5% !important;
        box-sizing: border-box;
    }
    
    .left-label-text {          
        font-family: 'Raleway';
        font-size: 90%;
        font-weight: 600;
        margin-left: -2%;
      }

      

      #text-voice{
        margin-top: 2%;
        margin-left: -10%;
    }

      .text-label-input {
        margin-left: 2%;
        font-weight: bold;
    }

    .text-label-output {
        margin-left: 3%;
        font-weight: bold;
    }
    
    .text-textarea {    
        margin-left: 5%;
        width: 120%;
        font-size: 16px;
        direction: rtl;
      }
    
    .sentences-label {
        margin-top: 2%;
        margin-right: 6%;
    }

    .middle-btn-pos{
        margin-top: 5%;
    }

}