
.static-tts-center-heading {
    font-family: 'Raleway';
    text-align: center !important;
    color: black;
    font-size: 215%;
    margin-top: 5%;
    display: inline-block;
}


.static-tts-center-heading:after {
    content: "";
    display: block;
    bottom: 5%;
    left: 44%;
    right: 44%;
    border-bottom: 2px solid rgb(47, 199, 238);
    padding-top: 20px;
    margin: 0 auto;
    width: 50%;
    
  }


.api-tts-code-heading {
    font-family: 'Raleway';
    font-size: x-large;
    color: black;
    text-align: left;
    margin-left: 2%;
    margin-bottom: 1%;
}



.first-box-api_tts {
    margin-right: 8%;
    margin-left: 8%;
    margin-top: 3%;
}



  .api_tts_text {
    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;
    margin-left: 2%;
    margin-bottom: 1%;
}



.api_tts-container-well {
    background-color: #eee !important;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(47, 46, 46, 0.05);
    text-align: left;
}





@media (max-width: 1400px){ 
    
    .static-tts-center-heading {
        font-size: 130%;
    }

    .api-tts-code-heading {
        font-size: large;
    }

    .first-box-api_tts {
        margin-right: 1%;
        margin-left: 1%;
        margin-top: 3%;
    }
    
    
    .api_tts_text {
        font-size: 90%;
    }




}