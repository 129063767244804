
.black-navbar-text-of-logo {
    color: rgb(250, 250, 250);
    font-size: 78%;
    text-decoration: none;
}


.profile-pic {
  margin-left: 80%;
  background-color: black;
}

.profile-pic:hover {
  background-color: black;
}



.get-started {
    color: #00e1ff;
    font-size: 80%;
}

.get-started:hover{
  color: #00e1ff;
 }


.white-color {
  color: white;
  font-size: 80%;
}
 
.white-color:hover{
  color: #00e1ff;
 }




 .black-bg  {
  background-color: black;
  color: white !important;
  font-family: 'Raleway';
 }


.black-bg .dropdown-item:hover {
  color: white !important;
}



.cyan-bar {
  position: relative;
}

.cyan-bar::after {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  height: 2px;
  background-color: #00e1ff; 
  transform: scaleX(0);
}

.cyan-bar:hover::after {
  bottom: -30% !important;
  transform: scaleX(1);
  opacity: 1;
}


@media (max-width: 1400px) {

  .black-navbar-text-of-logo {
    margin-left: 12%;
    font-size: 70%;
}

  .profile-pic {
    margin-bottom: 5%; 
    margin-left: 25%;
  }

  .menu-class-black {
    margin-left: 25%;
    margin-top: 5%;
  }

  .menu-class-black-drop{
    margin-left: 25%;
  }


}


.dropbtn-b {
  background-color: #000000;
  color: rgb(255, 255, 255);
  padding: 8px;
  border: none;
  cursor: pointer;
  font-size: 80%;
}

.dropbtn-b:hover {
  background-color: #000000;
  color:#00e1ff;
  padding: 8px;
  border: none;
  cursor: pointer;
  font-size: 80%;
}
