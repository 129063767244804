
.Page-sign-in {                                                     /*  for all pages  */
  background-image: url(../images/sign_in/signin-bg.png) ;     
  /* display: flex ; */
  align-items: center ;
  padding: 4%;
  background-size: cover;
  font-family: 'Raleway';
}



.sign-in {
  margin-bottom: 5%;
  text-align: center;
  font-family: 'Raleway-Medium';
  font-size: 233%;
  font-weight: lighter;
}

.sign-in::after {
  content: "";
  display: block;
  position: relative;

  height: 1.5px;
  left: 37.5%;
  width: 25%;

  margin-top: 1%;
  background-color: rgb(31, 223, 223);
}



.form-border-sign {
  border: 1.5px solid #37d9ef;
  border-radius: 3%;
  margin-bottom: 5%;
  margin-left: 25%;
  width: 50%;
  height: 100%;
  z-index: 2; 
  }

.inputField-sign {
  border-radius: 0px 0px 0px 0px;
  width: 63%;
  margin: 3% auto;
  margin-top: 10%;

  resize: none;
  border-radius: 0px !important;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  background: linear-gradient(to right, #00e1ff 20%, #00e1ff 80%) 0 100% / 100% 2px no-repeat, linear-gradient(to right, transparent 30%,transparent 70%) 0 0 / 100% 2px no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 0 0 / 2px 100% no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 100% 0 / 2px 100% no-repeat white;
  font-size: 1em;
}


.buttonField-sign {
  border: 1px solid;
  border-radius: 0px 0px 0px 0px;
  border-width: 1px;
  font-size: large;
  width: 63%;
  height: 40% !important;
  border-color: #37d9ef;
  margin-top: 5%;
  margin-bottom: 7%;
}

.buttonField-sign:hover {
  border: 1px solid;
  border-radius: 0px 0px 0px 0px;
  border-width: 1px;
  width: 63%;
  height: 17%;
  border-color: #37d9ef;
  margin-top: 5%;
  margin-bottom: 7%;
}


.links-sign-in{
  margin-bottom: 5% !important;
}


.links-sign-1 {
  font-size: 90%;
  color:  #37d9ef;
  text-decoration: none;
  padding-top: 1.5%;
  padding-right: 10%;
  padding-left: 10%;

}

.links-sign-1:hover {
  text-decoration: underline;
  color: #37d9ef;
}

.links-sign-2 {
  padding-top: 1.5%;
  padding-right: 10%;
  padding-left: 10%;
  font-size: 90%;
  color:  #37d9ef;
  text-decoration: none;
}

.links-sign-2:hover {
  text-decoration: underline;
  color: #37d9ef;
}





.login-error-clr {
font-family: 'Raleway';
color: red;
}





@media (max-width: 1400px) {

  .sign-in {
    text-align: center;
    font-family: 'Raleway-Medium';
    font-size: xx-large;
    font-weight: lighter;
  }

  .sign-in::after{
    margin-top: 2%;
    left: 35%;
    width: 30%;
    height: 1.5px;
  }

  .form-border-sign {
    margin-left: 15%;
    margin-bottom: 15%;
    margin-top: 5%;
    width: 75%;
    z-index: 2; 
  }

  .inputField-sign {
    width: 70%;
    margin: 3% auto;
    margin-top: 10%;
    font-size: 0.8em;
  }

  .buttonField-sign {
    width: 70%;
    font-size: large;
  }
  
  .buttonField-sign:hover {
    width: 70%;
    font-size: large;
  }


  .links-sign-1 {
    margin-left: -4.8%;
    font-size: 90%;
}

  .links-sign-2 {
    font-size: 90%;

  }

}


@media (max-width: 400px) { 

  .sign-in {
    font-size: x-large;
  }

  .sign-in::after{
    margin-top: 2%;
    width: 30%;
    height: 1.5px;
  }

  .form-border-sign {
    margin-left: 0%;
    margin-bottom: 15%;
    margin-top: 10%;
    width: 100%;
  }

  .inputField-sign {
    width: 90%;
  }

  .buttonField-sign {
    width: 89%;
    font-size: large;
  }
  
  .buttonField-sign:hover {
    width: 89%;
    font-size: large;
  }


  .links-sign-1 {
    margin-left: -5.8%;
    font-size: 80%;
}

  .links-sign-2 {
    font-size: 80%;

  }

}