#content-pos{
    flex: 1 0 auto;
}


.pos-banner {
    background-image: url('../images/cle_banner.png');
    min-height: 450px;
    max-height: 50%;
    width: 100.8%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}


.pos-banner-title {
    color: #101010;
    font-family: 'ProximaBold-Condensed';
    font-weight: bold;
    font-size: 230%;
    letter-spacing: -1px;
    margin-top: 13%;
}


.pos-service-row {
    font-family: 'Raleway';
    margin-top: 7%;
    margin-bottom: 7%;
    margin-left: 10%;
    margin-right: 10%;
}

.pos-service-text {
    text-align: justify;
    font-size: 90%;
    width: 70%;
    margin-left: 20%;

}

.pos-service-line {
    width: 100%;
    color: rgb(191, 185, 185);
}

.pos-service-btn {
        color: black;
        border: solid #00e1ff thin;
        padding-left: 1em;
        padding-right: 1em;
        background: white;
        border-radius: 0;
        text-align: justify;
        font-size: 20px;
        padding: 10px;

        width: 35%;
        margin-top: 5%;
        margin-left: 21%;
}

.pos-service-btn:hover {
    color: black;
}


.pos-api-ref {
    background-color: #02C0F0;
    width: 55%;
    height: 95%;
    margin-left: 5%;
}

.pos-api-ref:hover{
    background-color: #00e1ff;
}


.pos-api-ref-text {
    color: white;
    margin-top: 2%;
    font-weight: bold;
}


.pos-link-pdf {
    color: #00E1FF;
}

.pos-link-pdf:hover {
    color: #00E1FF;
    text-decoration: underline;
}


@media (max-width: 800px){  

    .pos-banner-title {
        font-family: 'ProximaBold-Condensed';
        font-size: 200%;
        margin-top: 35%;
    }


.pos-service-row {
    margin-left: 0%;
    margin-right: 0%;
}

.pos-service-text {
    width: 80%;
    margin-left: 11%;

}

.pos-service-btn {
        color: black;
        border: solid #00e1ff thin;
        padding-left: 0em;
        padding-right: 0em;
        font-size: 80%;
        padding: 10px;

        width: 45%;
        margin-top: 5%;
        margin-left: 25%;
        margin-bottom: 5%;

}


.pos-api-ref {
    width: 60%;
    height: 105%;
    margin-left: 20%;
}


}