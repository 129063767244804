.Page-for-reset {
    width: 95%;
    height: 100%;
}


.first-box-reset-after-login  {
    margin-right: 8%;
    margin-left: 10%;
}

.reset-container-well {
    background-color: #eee !important;
    min-height: 20px;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);

    height: auto;
    height: 370px;
}


.formating {
    font-family: 'Raleway';
}


.alert-info {
    color: #31708f;
    background-color: #d9edf7;
    border-color: #bce8f1;
}

.alert {
    padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;

    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;

    margin-right: 30%;
}


.close {
    float: right;
    font-size: 21px;
    font-weight: 700;
    line-height: 1;
    color: #000;
    text-shadow: 0 1px 0 #fff;
    filter: alpha(opacity=20);
    opacity: .2;
}

.error-message-reset{
    font-family: 'Raleway';
    color: red;
    margin-bottom: 2%;
}

.reset-password-label{
    font-weight: bold;
    font-size: 90%;
    margin-left: 7.5%;
}

.reset-password-label-1{
    font-weight: bold;
    font-size: 90%;
    margin-left: 8.4%;
}


.box-cover{
    margin-left: 16%;
    margin-top: -2.1%;
}


.input-group {
    position: relative;
    display: table;
    border-collapse: separate;
}



.input-group-addon {
    width: 1%;
    white-space: nowrap;

    padding: 6px 12px;
    font-size: 15px;
    line-height: 1;
    color: #555;
    background-color: #eee;
    border: 1px solid #ccc;
    border-radius: 4px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right: 0;
}

.reset-password-box {
    width: 50%;
    margin-right: 17%;

    padding: 6px 12px;
    font-size: 15px;
    line-height: 1;
    color: #555;
    border: 1px solid #ccc;
    border-radius: 4px;

    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}




.reset-password-button {
    font-family: 'Raleway';
    font-size: 90%;
    width: 28%;
    background-color: #337ab7;
    border-color: #2e6da4;

    margin-right: 10%;
    margin-top: 3%;
}

.reset-password-button:hover {
    font-family: 'Raleway';
    font-size: 90%;
    width: 28%;
    background-color: #337ab7;
    border-color: #2e6da4;

    margin-right: 10%;
    margin-top: 3%;
}


@media (max-width: 1400px){ 

    .first-box-reset-after-login {
        margin-left: -6%;
    }
    
    .reset-container-well {
        height: 100%;
        width: 107%;
    }
    
    
    .alert {
        padding: 3%;
        margin-bottom: 7%;
        margin-right: 3%;
    }
    
    
    .error-message-reset{
        margin-bottom: 2%;
    }
    
    .reset-password-label{
        margin-left: -26.5%;
        margin-bottom: 3%;
    }

    .reset-password-label-1{
        margin-left: -29%;
        margin-bottom: 3%;
    }
    
    
    .box-cover{
        margin-left: -1%;
    }
    
    
    .reset-password-box {
        width: 81%;
        margin-right: 1%;
    }
    
    .reset-password-button {
        width: 95%;
        margin-right: 1%;
    }
    
    .reset-password-button:hover {
        width: 95%;
        margin-right: 1%;
    }

  }