/* section 1  */
.Page-pricing {                                                 
    background-image: url(../images/pricing/pricing_banner.png) ;         
    backdrop-filter: blur(10px) !important;
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    height: 62dvh ;
    background-size: cover ;
    background-position: center ;
    
  }


  .banner-title-pricing                          
  {
      color: black;
      font-size: 310%;
      font-weight: bold;
  }
  
  
  .banner-tagline-pricing                      
  {
      font-size: 250%;
      color: white;
      font-family: 'Raleway', fantasy;
      margin-top: 0px !important;
  }




/* section 2  */
  .center-heading-pricing {
    font-family: 'Raleway';
    text-align: center !important;
    color: rgb(67, 66, 66) ;
    font-size: 215%;
    margin-top: 5%;
    margin-bottom: 3% ; 
    display: inline-block;
}


.center-heading-pricing:after {
  content: "";
  display: block;
  bottom: 5%; 

  border-bottom: 2px solid rgb(125, 219, 243); 
  margin-top: 2.5%;
}


.box-size {
    border: 1px black solid;
    width: 35%;

    margin-left: 32.5%;
    text-align: justify;
    font-size: 14px;
    font-family: 'Raleway';
}

.box-text {
  margin-left: 3%;
  margin-right: 3%;
}


/* section 3  */
.table-price {
  width: 100%;
  margin-bottom: 5%;
  margin-left: 32%;

  background-color: transparent;
  font-family: 'Raleway';
  font-size: 85%;
  text-align: justify;
  align-items: center;
}


.service-name {
  text-transform: uppercase;
  font-weight: bolder;
  text-align: center;
  font-size: 0.9em;
}


.service-price-detail {
  margin-bottom: -2%;
}


.service-price-detail::after {
  content: "";
  display: block;
  width: 70%;

  border-bottom: 2px solid rgb(125, 219, 243); 
}



/* section 4 */

#payment {
  font-family: 'Raleway';
  background: rgb(31,31,35);
  color: white;
  justify-content: space-between;
}



.center-heading-payment {
  font-family: 'Raleway';
  text-align: center !important;
  color: rgb(255, 255, 255) ;
  font-size: 215%;
  margin-top: 5%;
  margin-bottom: 3% ; 
  display: inline-block;
}


.center-heading-payment:after {
content: "";
display: block;

border-bottom: 2px solid rgb(125, 219, 243); 
margin-top: 4%;
}


.payment-row {
  width: 100%;
  padding-top: 1%;
  padding-bottom: 2%;
  align-items: center;
  align-content: center;
}

.payment-column-1 {
  padding-top: 3%;
  padding-bottom: 5%;
  margin-left: 9%;
}


.payment-column-2 {
  padding-top: 3%;
  padding-bottom: 5%;
  margin-left: -10%;
}


.payment-column-3 {
  padding-top: 3%;
  padding-bottom: 5%;
  margin-left: -10%;
}


.dot {
  height: 50px;
  width: 50px;
  background-color: #00e1ff;
  border-radius: 50%;
  display: inline-block;
  font-size: xx-large !important;
}

.payment-column-text {
  margin-top: 10%;
  margin-left: 23%;
  font-size: 85%;
  text-align: center;

  width: 60%;
}


.footnote{
  font-size: 90% !important;
  margin-top: 3%;
}


@media (max-width: 1400px){ 

  .box-size {
    border: 1px black solid;
    width: 90%;
    margin-left: 6%;
}


.table-price {
  margin-left: 0%;
}

.payment-column-1 {
  margin-left: -10%;
}

}