
 .blackish-bg-white-text{
  background-color: #1f1f23;
  color: white;
}


  .dashboard-dropdown-menu {         
  background-color: #1f1f23;
  color: aqua;
}


  .dropdown-text-dashboard{                     
    text-decoration: none;
    color: rgb(248, 246, 246) !important;
    font-size: small; 
    margin-left: 10%;
    margin-top: -5%;
    margin-bottom: -6%;
  } 


  .dropdown-text-dashboard:hover{
    background-color: #1f1f23;
    color: aqua !important; 
  }



p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  margin: 0 0 10px;
}



.services-title {
  background-color: black;
  color: white;
}


.dropdown-divider {
  margin-top: -1%;
  border-top: 1px solid #6f6a6a;
}

