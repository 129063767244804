.admin-service-page {
    width: 100%;
    height: 100%;
    font-family: "Raleway";
}

.breadcrumb {
    padding-top: 1px;
    padding-bottom: 1px;
    list-style: none;
    background-color: #f5f5f5;
}

.service-management {
    height: 100%;
    margin-top: 1%;
    margin-bottom: 5%;
}

#addservbtn{
    font-family: 'Raleway';
    background-color: #5cb85c;
    border-color: #4cae4c;
    float: left;
    margin-left: 11%;
}





.container-admin-services {
    padding-right: 11%;
    padding-left: 11%;
    padding-top: 2%;

    width: 100%;
    display: table;
    content: " ";

    border-top-left-radius: 3px;
    border-top-right-radius: 3px;
}




.admin-panel {
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
}


.admin-services-heading {
    font-family: 'Raleway';
    background-color:#f1f0f0;
    text-align: left;
    padding: 10px 15px;
    border-bottom: 1px solid #ddd;
    font-size: 90%;
}



.admin-panel-body {
    padding: 15px;
}




.admin-table {
    width: 100%;
    border: 1px solid #ddd;
    background-color: transparent;
}

.admin-table>caption+thead>tr:first-child>th, 
.admin-table>colgroup+thead>tr:first-child>th, 
.admin-table>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.admin-table thead > tr > th {
    border-bottom: 2px solid;
    border-color: #ddd;
    text-align: left;
    font-family: 'Raleway';
    font-size: 90%;
}

.admin-table>tbody>tr>td  {
    font-family: 'Raleway';
    font-size: 90%;
    text-align: left;
}

.admin-table>tbody>tr>td, 
.admin-table>tfoot>tr>td, 
.admin-table>tfoot>tr>th, 
.admin-table>thead>tr>td, 
.admin-table>thead>tr>th {
    padding: 7px;
    line-height: 1.42857143;
    vertical-align: top;
    border-top: 1px solid #ddd;
}

.changeServiceBtn {
    font-family: 'Raleway';
    width: 55%;
    margin-right: 2%;
    margin-left: 9%;
}




/* section 3 */

.service-form-properties{
    font-family: 'Raleway';
}

.services-form-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
}


.service-form-label {
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: bold;
    flex: 1;
}

.service-form-box {
    font-size: small;
    width: 60%;
    margin-right: 30%;
    flex: 1;
}




.required-fields {
    color: red;
}