
.first-box-roman {
    margin-right: 8%;
    margin-left: 8%;
}


.roman-container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 500px !important;
}


.roman-fields-format {
    font-family: 'Raleway';
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}


.roman-heading {
  font-family: 'Raleway';
  font-size: 24px;
  padding-top: 2%;
  padding-bottom: 2%;
}

.left-label-roman {             
    padding-right: 25%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }


#roman-voice{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}


.roman-textarea {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;

    width: 310%;
    height: 49%;
    font-size: 16px;
    direction: ltr;
  }


  .roman-button {
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;

    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    margin-right: 10px !important;
    margin-left: 15px !important;

    font-size: 90%;
}


.roman-label {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 70%;
}


.roman-input-label {
  font-family: 'Raleway';
  font-weight: bold;
  margin-left: 2%;
  width: 100%;
}

.flex-container-button-roman {
    display: flex;
    flex-wrap: wrap;
}



.loading-spinner {
  border: 4px solid rgba(0, 0, 0, 0.3);
  border-top: 4px solid #000000;
  border-radius: 50%;
  width: 100px; 
  height: 100px; 
  animation: spin 2s linear infinite;
}

.loading-overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.499);
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  top: 0;
  left: 0;
}


@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}




@media (max-width: 1400px){ 
    
    .roman-container-well {
      background-color: #eee !important;
      border: 1px solid #e3e3e3;
      border-radius: 4px;
      box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
      width: 99vw;
      height: 550px !important;
      margin-left: -12% !important;
      box-sizing: border-box;
    }
    
    
    .left-label-roman {          
        font-family: 'Raleway';
        font-size: 90%;
        font-weight: 600;
        margin-left: -30%;
      }


      .flex-container-button-roman {
        width: 100%;
    }



      #roman-voice{
        margin-top: 1%;
        margin-left: -20%;
    }

      .roman-label {
        margin-left: -5%;
    }

    .roman-input-label {
      width: 100%;
    }
    
    .roman-textarea {    
      direction: ltr;
      margin-left: 5%;
      width: 125%;
      font-size: 16px;
    }

    
    .middle-btn-roman{
        margin-top: 5%;
    }

}