

.static-api_keyword-center-heading {
    font-family: 'Raleway';
    text-align: center !important;
    color: black;
    font-size: 215%;
    margin-top: 5%;
    display: inline-block;
}


.static-api_keyword-center-heading:after {
    content: "";
    display: block;
    bottom: 5%;
    left: 44%;
    right: 44%;
    border-bottom: 2px solid rgb(47, 199, 238);
    padding-top: 20px;
    margin: 0 auto;
    width: 50%;
    
  }



.first-box-api_keyword {
    margin-right: 8%;
    margin-left: 8%;
    margin-top: 3%;
}



.api_keyword_text {
    font-family: 'Raleway';
    font-size: 90%;
    margin-left: 2%;
    margin-bottom: 1%;
    text-align: left;
}


.api_keyword-container-well {
    background-color: #eee !important;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(47, 46, 46, 0.05);
    text-align: left;
}






@media (max-width: 1400px){ 
    
    .static-api_keyword-center-heading {
        font-size: 130%;
    }
    
    .first-box-api_keyword {
        margin-right: 1%;
        margin-left: 1%;
        margin-top: 3%;
    }
    
    .api_keyword_text {
        font-size: 90%;
    }



}