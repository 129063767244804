
  .reset-page {                                               
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    padding: 10%;
    background-size: contain ;
    background-position: cover ;

    font-family: 'Raleway';
    
  }


  .reset-heading {
    position: relative;
    position: absolute;
    margin-top: -9%;
    left: 0%;
    text-align: center;
    font-family: 'Raleway-Medium';
    font-size: 30px;
    display: inline-block;
    font-weight: lighter;

  }

  .reset-heading:after {
    content: "";
    display: block;
    position: absolute;

    height: 2px;
    left: 40%;
    width: 20%;

    margin-top: 1%;
    background-color: rgb(31, 223, 223);
  }


  
.inputField-reset {
  border-radius: 0px 0px 0px 0px;
  width: 65%;
  margin: 3% auto;
  margin-top: 6%;

  resize: none;
  border-radius: 0px !important;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  background: linear-gradient(to right, #00e1ff 20%, #00e1ff 80%) 0 100% / 100% 2px no-repeat, linear-gradient(to right, transparent 30%,transparent 70%) 0 0 / 100% 2px no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 0 0 / 2px 100% no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 100% 0 / 2px 100% no-repeat white;
  font-size: 1em;
}


.buttonField-reset {
  border: 1px solid;
  border-radius: 0px 0px 0px 0px;
  border-width: 1px;
  width: 65%;
  height: 10%;
  border-color: #37d9ef;
  margin-top: 6%;

  font-family: 'Raleway';
}

.buttonField-reset:hover {
  border: 1px solid;
  border-radius: 0px 0px 0px 0px;
  border-width: 1px;
  width: 65%;
  height: 10%;
  border-color: #37d9ef;
  margin-top: 6%;

  font-family: 'Raleway';
}



.form-border-reset {
  border: 1.5px solid #37d9ef;
  border-radius: 3%;

  width: 60%;
  min-height: 100%;
  height: 400px;
  margin-top: 3%;

  z-index: 2; 
}


.reset-password-msg {
  font-size:x-large;
  background-color: rgb(217, 215, 215);
  color: black;
  border: 1.5px solid #d9e0e0;
  border-radius: 0.5%;

  padding: 5%;
  width: 70%;
  min-height: 100%;
  margin-top: 3%;
  align-items: center;
  z-index: 2; 

}


.reset-password-error-msg {
  color: red;
  font-family: "Raleway";
}

@media (max-width: 1400px) {

  .reset-page { 
    height: 100%;
    padding: 0.2%;
    margin-top: 20%;
    margin-bottom: 15%;
  }

  .form-border-reset {
    width: 100%;
    height: 300px;
  }


  .reset-heading {
    margin-top: -15%;
    font-size: 24px;
  }

  .reset-heading:after {
    left: 35%;
    width: 30%;
    margin-top: 3%;
  }

  
  .inputField-reset {
    width: 100%;
    margin-top: 6%;
    font-size: 0.85em;
  }


  .buttonField-reset {
    width: 95%;
    height: 10%;
    margin-top: 6%;
  }

  .buttonField-reset:hover {
    width: 95%;
    height: 10%;
    margin-top: 6%;
  }


}