/* section 1  */
  .center-heading-pricing-login {
    font-family: 'Raleway';
    text-align: center !important;
    color: rgb(67, 66, 66) ;
    font-size: 200%;
    margin-top: 5%;
    margin-bottom: 3% ; 
    display: inline-block;
    font-weight: 500;
}

.center-heading-pricing-login:after {
  content: "";
  display: block;
  bottom: 5%; 
  width: 180%;
  border-bottom: 2px solid rgb(125, 219, 243); 
  margin-top: 8%;
  margin-left: -40%;
}




/* section 2  */
.table-price-login {
  width: 100%;
  margin-bottom: 5%;
  margin-left: 35%;
  background-color: transparent;
  font-family: 'Raleway';
  font-size: 85%;
  text-align: justify;
  align-items: center;
}


.table-price-login-heading1::after {
  content: "";
  display: block;
  bottom: 5%; 
  width: 141%;
  border-bottom: 4px solid #00e1ff;
}

.table-price-login-heading{
  padding-left: 10% !important;
}

.table-price-login-heading::after {
  content: "";
  display: block;
  bottom: 5%; 
  width: 70% ;
  border-bottom: 4px solid #00e1ff;
}


.table-price-login>caption+thead>tr:first-child>th, 
.table-price-login>colgroup+thead>tr:first-child>th, 
.table-price-login>thead:first-child>tr:first-child>th {
    border-top: 0;
}

.table-price-login thead > tr > th {
    border-color: #ddd;
    text-align: left;
    font-family: 'Raleway';
    font-size: 90%;
}

.table-price-login>tbody>tr>td  {
    font-family: 'Raleway';
    font-size: 100%;
    text-align: left;
}

.table-price-login>tbody>tr>td, 
.table-price-login>tfoot>tr>td, 
.table-price-login>tfoot>tr>th, 
.table-price-login>thead>tr>td, 
.table-price-login>thead>tr>th {
    padding: 7px;
    line-height: 1.42857143;
    vertical-align: top;
}



.service-name-login {
  font-weight: lighter;
  text-align: left;
  font-size: 105%;
}


.service-price-detail-login{
  padding-left: 10% !important;
}

.service-price-detail-login::after {
  content: "";
  display: block;
  width: 130%;
  margin-left: -62%;
  border-bottom: 1px solid rgb(189, 193, 194); 
}




@media (max-width: 1400px){ 

.table-price-login {
  margin-left: 0%;
}

.table-price-login-heading::after {
  width: 100%;
}

.service-price-detail-login::after {
  width: 200%;
  margin-left: -100%;
}

}