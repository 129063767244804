
.first-box-stemmer {
    margin-right: 8%;
    margin-left: 8%;
}


.stemmer-container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 500px;
}


.stemmer-fields-format {
    font-family: 'Raleway';
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}



.left-label-stemmer {             
    padding-right: 25%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }


#stemmer-voice{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}


.stemmer-textarea {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;

    width: 310%;
    height: 49%;
    font-size: 16px;
    direction: rtl;
  }


.stemmer-button {
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;

    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    margin-right: 10px !important;
    margin-left: 15px !important;

    font-size: 90%;
}


.stemmer-label {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 70%;
}



.flex-container-button-stemmer {
    display: flex;
    flex-wrap: wrap;
}


.stemmer-error-color {
    color: red;
    font-family: 'Raleway';
}


@media (max-width: 1400px){ 
    
    .stemmer-container-well {
        width: 99.5vw;
        height: 550px !important;
        margin-left: -12.3% !important;
        box-sizing: border-box;
    }
    
    
    .left-label-stemmer {          
        font-family: 'Raleway';
        font-size: 90%;
        font-weight: 600;
        margin-left: -30%;
      }


      .flex-container-button-stemmer {
        width: 100%;
    }



      #stemmer-voice{
        margin-top: 1%;
        margin-left: -20%;
    }

      .stemmer-label {
        margin-left: -5%;
        font-family: 'Raleway';
        font-weight: bold;
    }
    
    .stemmer-textarea {    
        margin-left: 5%;
        width: 125%;
        font-size: 16px;
        direction: rtl;
      }
    
    .middle-btn-stemmer{
        margin-top: 5%;
    }

}