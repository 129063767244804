#content-tts{
    flex: 1 0 auto;
}


.tts-banner {
    background-image: url('../images//tts/tts-bg.png');
    min-height: 450px;
    max-height: 50%;
    width: 100.8%;
    background-size: 100% 100%;
    background-repeat: no-repeat;
}


.tts-banner-title {
    color: #101010;
    font-family: 'ProximaBold-Condensed';
    font-size: 230%;
    margin-top: 13%;
}


.tts-service-row {
    font-family: 'Raleway';
    margin-top: 7%;
    margin-bottom: 7%;
    margin-left: 10%;
    margin-right: 10%;
}

.tts-service-text {
    text-align: justify;
    font-size: 89%;
    width: 71%;
    margin-left: 19%;

}

.tts-service-btn {
        color: black;
        border: solid #00e1ff thin;
        padding-left: 1em;
        padding-right: 1em;
        background: white;
        border-radius: 0;
        text-align: justify;
        font-size: 20px;
        padding: 10px;

        width: 35%;
        margin-top: 5%;
        margin-left: 21%;
}

.tts-service-btn:hover {
    color: black;
}


.tts-api-ref {
    background-color: #02C0F0;
    width: 50%;
    height: 90%;
    margin-left: 5%;
}

.tts-api-ref:hover{
    background-color: #00e1ff;
}


.tts-api-ref-text {
    color: white;
    margin-top: 2%;
    font-weight: bold;
}


@media (max-width: 800px){  

    .tts-banner-title {
        font-family: 'ProximaBold-Condensed';
        font-size: 200%;
        margin-top: 35%;
    }


.tts-service-row {
    margin-left: 0%;
    margin-right: 0%;
}

.tts-service-text {
    width: 80%;
    margin-left: 11%;

}

.tts-service-btn {
        color: black;
        border: solid #00e1ff thin;
        padding-left: 0em;
        padding-right: 0em;
        font-size: 80%;
        padding: 10px;

        width: 45%;
        margin-top: 5%;
        margin-left: 25%;
        margin-bottom: 5%;

}


.tts-api-ref {
    width: 60%;
    height: 105%;
    margin-left: 20%;
}


}