
.first-box-profanity {
    margin-right: 8%;
    margin-left: 8%;
}


.profanity-container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 500px;
}


.profanity-fields-format {
    font-family: 'Raleway';
    position: relative;
    min-height: 1px;
    padding-right: 15px;
    padding-left: 15px;
    float: left;
}


.profanity-heading {
    font-family: 'Raleway';
    font-size: 24px;
    padding-top: 2%;
    padding-bottom: 2%;
}


.left-label-profanity {             
    padding-right: 25%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
  }


#profanity-voice{
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}


.profanity-textarea {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;

    width: 310%;
    height: 49%;
    font-size: 16px;
    direction: rtl;
  }


.profanity-button {
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;

    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    margin-right: 10px !important;
    margin-left: 15px !important;

    font-size: 90%;
}


.profanity-label {
    font-family: 'Raleway';
    font-weight: bold;
    margin-right: 70%;
}



.flex-container-button-profanity {
    display: flex;
    flex-wrap: wrap;
}


@media (max-width: 1400px){ 
    
    .profanity-container-well {
        background-color: #eee !important;
        border: 1px solid #e3e3e3;
        border-radius: 4px;
        box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
        width: 99vw;
        height: 550px !important;
        margin-left: -12% !important;
        box-sizing: border-box;
    }
    
    
    .left-label-profanity {          
        font-family: 'Raleway';
        font-size: 90%;
        font-weight: 600;
        margin-left: -30%;
      }


      .flex-container-button-profanity {
        width: 100%;
    }



      #profanity-voice{
        margin-top: 1%;
        margin-left: -20%;
    }

      .profanity-label {
        margin-left: -5%;
        font-family: 'Raleway';
        font-weight: bold;
    }
    
    .profanity-textarea {    
        margin-left: 5%;
        width: 125%;
        font-size: 16px;
        direction: rtl;
      }
    
    .middle-btn-profanity{
        margin-top: 5%;
    }

}