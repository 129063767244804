
/* section 2 */


.fellow-award-table{
    font-size: 95%;
    border-collapse: collapse;
    margin-left: 5%;
    margin-bottom: 2%;
    text-align: center;
    width: 90%;
}

.fellow-award-table th {
    background: #1b92c0;
    color: #FFF;
  }
  
  .fellow-award-table th, .fellow-award-table td {
    padding: 6px; 
  }


  .center-heading-fellow-award {
    font-family: 'Raleway';
    text-align: center !important;
    color: black ;
    font-size: 215%;
    margin-top: 5%;
    margin-bottom: 13% ; 
    display: inline-block;
}


.center-heading-fellow-award:after {
  content: "";
  display: block;
  bottom: 5%; 
  left: 44%;
  right: 44%;
  border-bottom: 2px solid rgb(47, 199, 238);
  padding-top: 20px;

  margin: 0 auto;
  
}
  

  .fellow-call-proposal {
    font-size: 35px;
    font-family: 'Raleway';
    margin-top: -10%;
    margin-bottom: 2%;
    color: rgb(58, 56, 56);
    /* text-align: center; */
}





  .fellow-award-heading {
    text-align: left;
    color: #ffffff;
    background-color: #1b92c0;
    padding-top: 2%;
    padding-bottom: 1%;
    text-align: left;
    font-size: 100%;
}


.paragraph_text-fellow-award {
    color: black;
    text-align: left;
    font-size: 90%;
}







.fellow-form-label {
    text-align: left;
    font-weight: bold;
    font-size: 90%;
    color: black;
}

.red-star {
    color: red;
    font-size: 21px;
}

.fellow-form-input {
    margin-left: 10%;
    text-align: left;
}


.fellow-btn {
    color: white;
    background-color: #1b92c0;
    font-weight: bold;
    box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2), 0 6px 20px 0 rgba(0,0,0,0.19);
    padding: 5px 5px;
    border: 0;
}

.fellow-btn:hover {
    opacity: 1;
    transition: 0.3s;
}



.fellow-box {
    margin: 1%;
}




.multiselect {
    position: relative;
    display: inline-block;
    width: 45%;
  }
  
  .selectBox {
    position: relative;
  }
  
  .selectBox select {
    width: 100%;
  }
  
  .overSelect {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
  }
  
  #checkboxes {
    border: 1px solid #ccc;
    max-height: 310%;
    overflow-y: auto;
    position: absolute;
  }
  
  
  #checkboxes label:hover {
    background-color: #2c92ff;
    font-weight: bold;
  }
  

@media (max-width: 1400px) {

    .fellow-award-table{
        font-size: 70%;
        border-collapse: collapse;
        margin-left: 0%;
        text-align: center;
        width: 100%;
    }
      
      .fellow-award-table th, .fellow-award-table td {
        padding: 4px; 
      }

      .fellow-award-table tr {
        font-size: 100%;
      }

    .center-heading-fellow-award {
        font-size: 200%;
        margin-top: 5%;
        margin-bottom: 13% ; 
        margin-left: 5%;
    }

    .center-heading-fellow-award:after {
        content: "";
        display: block;
        bottom: 5%;
        width: 88%;
    }


    .paragraph_text-fellow-award {
        font-size: 130%;
    }
    

    .fellow-form-label {
        text-align: left;
        font-weight: bold;
        font-size: 100% !important;
    }



    .fellow-btn {
        margin-left: 50%;
        width: 50%;
    }

    .fellow-call-proposal {
        font-size: 180% !important;
    }


    .fellow-form-input {
        align-items: left;
        width: 95% !important;
    }



}