@font-face {
    font-family: 'nafees-nastaleeq';
    src: url('../fonts/Nafees_Nastaleeq.ttf') format('truetype');
  }
 
  .urduFont {
    font-family: 'nafees-nastaleeq';
}

.box-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    height: 400px; /* Increase height as needed */
    margin: 0;
    padding: 1rem;
    position: relative; /* For positioning buttons */
    padding-bottom: 2rem; /* Reduce space at the bottom if needed */
}

.button-container {
    display: flex;
    justify-content: space-between; /* Space between buttons */
    position: absolute;
    bottom: 1rem; /* Distance from the bottom of the container */
    left: 1rem; /* Distance from the left side */
    right: 1rem; /* Distance from the right side */
}
.input-container .button-container,
.output-container .button-container {
    flex-direction: row; /* Buttons should be in a row */

}

.flex-container-horizontal {
    display: flex;
    flex-direction: row;
    gap: 0.2rem; /* Adjust this value for spacing between elements */
}

.input-container, .output-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: calc(100% - 2rem); /* Adjust to fit within the box-container height */
}

.image-placeholder{
    flex: 1;
    padding: 130px;
    background-color: #fff; /* White background */
    border-radius: 4px; /* Rounded corners */
}

 .domain-textarea {
    flex: 1;
    padding: 15px;
    background-color: #fff; /* White background */
    border-radius: 4px; /* Rounded corners */
    direction: rtl; /* Place scrollbar on the left */

}

.image-preview-container {
    width: 100%;
    height: 500px; /* Adjust the height as needed */
    border: 1px solid #ccc;
    /* overflow: auto; Allow scrolling */
    position: relative;
    direction: rtl; /* Place scrollbar on the left */
    overflow-y: auto; /* Allow vertical scrolling */
    overflow-x: auto !important; /* Allow horizontal scrolling */
    white-space: nowrap !important; /* Prevent text from wrapping */

}

.image-preview {
    width: 500px; /* Allow width to adjust automatically */
    height: auto; /* Allow height to adjust automatically */
    max-width: none; /* Remove max-width restriction */
    max-height: none; /* Remove max-height restriction */
}



.domain-textarea {
    resize: none; /* Prevent resizing if needed */
    min-height: 150px; /* Minimum height */
    width: 100%;
    height: 100%; /* Ensure it takes up full height of the container */
}

.domain-label {
    font-family: 'Raleway';
    font-weight: bold;
    margin-bottom: 10px; /* Margin below the label */
}

.first-box-domain {
    margin: 0 8%;
}

.domain-container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
    width: 100%;
    height: 530px; /* Increase height as needed */
}

.domain-fields-format {
    font-family: 'Raleway';
    padding: 0 15px;
    display: flex;
    flex-direction: column;
    gap: 0.5rem; /* Space between options */
}

.left-label-domain {             
    padding-right: 25%;
    padding-bottom: 2%;
    font-family: 'Raleway';
    font-size: 90%;
    font-weight: 600;
}

#domain-voice {
    color: grey;
    margin-left: -4%;
    width: 80%;
    margin-bottom: 5%;
}

/* .domain-button {
    font-family: 'Raleway';
    padding: 0.25em 2em !important;
    color: #000080 !important;
    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
    margin-right: 10px !important;
    margin-left: 15px !important;
    font-size: 90%;
} */

.domain-button {
    font-family: 'Raleway';
    padding: 0.25em 2em;
    color: #000080;
    background-color: transparent;
    border: 2px solid #000080;
    border-radius: 10rem;
    transition: .2s;
    margin-right: 10px;
    margin-left: 15px;
    font-size: 90%;
}

.btn.domain-button:hover {
    background-color: #dee0e0 !important;
    color: #7c8185 !important;
}


.middle-btn-domain {
    display: flex;
    justify-content: center;
    margin-top: 5%;
}

/* .image-placeholder {
    height: 250px; 
} */

@media (max-width: 1400px) {
    .domain-container-well {
        width: 99.5vw;
        height: 650px !important; /* Increase height for smaller screens */
        margin-left: -12.3% !important;
        box-sizing: border-box;
    }
    
    .left-label-domain {
        font-family: 'Raleway';
        font-size: 90%;
        font-weight: 600;
        margin-left: -30%;
    }

    .flex-container-button-domain {
        width: 100%;
    }

    #domain-voice {
        margin-top: 1%;
        margin-left: -20%;
    }

    .domain-label {
        margin-left: -5%;
        font-family: 'Raleway';
        font-weight: bold;
    }
    
    .domain-textarea {
        margin-left: 0;
        width: 100%;
        font-size: 16px;
        direction: rtl;
    }

    .middle-btn-domain {
        margin-top: 5%;
    }
}