
.footer-container
{
    width: 100% !important;
    font-size: 15px;
}


.social-icon {
    padding: 1%;
}

.white
{
    color: white;
}

.footer-link
{
    margin: 1%;
    text-decoration: none;
    white-space: nowrap;
}

.footer-link:hover {
    color: #ffffff;
    text-decoration: underline;
  }


.footer-row
{
    padding-top: 1%;
    padding-bottom: 1%;
    align-items: center;
    align-content: center;
    width: 100%;
}


#footer
{
    background: #101010;
    padding: 1%;
    color: white;
    align-self: flex-end;
    font-family: 'Raleway';
    
}

.footer-column
{
    align-items: flex-start;
    width: 100%;
}

#footer-cle
{
    color: #00e1ff;
}



#footer-links
{
    padding: 2%;
    text-decoration: none;
}

  
  .flex-container {
    margin-left: 27%;
    display: flex;
    flex-wrap: wrap;
  }
  
  .flex-item {
    flex: 0 0 auto;
    width: 12%;
  }


  .flex-item1 {
    flex: 0 0 auto;
    width: 12%;
  }



@media (max-width: 1400px){  

    .footer-container
{
    font-size: 13px;
}

    .flex-container {
        margin-left: 1%;
        display: flex;
        flex-wrap: wrap;
      }

      .flex-item {
        width: 32%;
      }

      .flex-item1 {
        margin-left: 15%;
        width: 25%;
      }
        
}