.Page-register {                      
  display: flex ;
  min-height: 100%;
  font-family: 'Raleway';
}

.register {
position: absolute;
font-family: 'Raleway-Medium';
font-size: 36px;
display: inline-block;
font-weight: lighter;

margin-top: 3.5%;
margin-left: -6%;

}

.register::after {
content: "";
display: block;
position: absolute;

height: 1.5px;
margin-left: -9%;
width: 115%;

margin-top: 10%;
background-color: rgb(31, 223, 223);
}

#reg-signin {
margin-left: -1%;
font-family: 'Raleway';
font-size:14px;
}
.cle-blue {
text-decoration: none;
color: #00e1ff;
}
.cle-blue:hover{
text-decoration: underline;
color: #00e1ff;
}




.reg-inputField {
border-radius: 0px 0px 0px 0px;
width: 47%;
margin: 1% auto;
margin-top: 5%;

resize: none;
border-radius: 0px !important;
border-top-width: 0px;
border-left-width: 0px;
border-right-width: 0px;
border-bottom-width: 0px;
background: linear-gradient(to right, #00e1ff 20%, #00e1ff 80%) 0 100% / 100% 2px no-repeat, linear-gradient(to right, transparent 30%,transparent 70%) 0 0 / 100% 2px no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 0 0 / 2px 100% no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 100% 0 / 2px 100% no-repeat white;

font-size:14px;

}


.reg-inputField-address {
border-radius: 0px 0px 0px 0px;
width: 97%;
margin: 1% auto;
margin-top: 5%;

resize: none;
border-radius: 0px !important;
border-top-width: 0px;
border-left-width: 0px;
border-right-width: 0px;
border-bottom-width: 0px;
background: linear-gradient(to right, #00e1ff 20%, #00e1ff 80%) 0 100% / 100% 2px no-repeat, linear-gradient(to right, transparent 30%,transparent 70%) 0 0 / 100% 2px no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 0 0 / 2px 100% no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 100% 0 / 2px 100% no-repeat white;
font-size:14px;

}


.check {
margin-left: 2%;
margin-top: 3%;
font-family: 'Raleway';
font-size:14px;
}



.register-buttonField {
border: 1px solid;
border-radius: 0px 0px 0px 0px;
border-width: 1px;
width: 35%;
height: 10%;
border-color: #37d9ef;

margin-bottom: 7%;
margin-left: 60%;
font-family: 'Raleway';
font-size:14px;
}

.register-buttonField:hover {
border: 1px solid;
border-radius: 0px 0px 0px 0px;
border-width: 1px;
width: 35%;
height: 10%;
border-color: #37d9ef;

margin-bottom: 7%;
margin-left: 60%;
font-family: 'Raleway';
font-size:14px;
}



#sidepanel-col {
  height: 100%;
  padding: 20px;
  padding-top: 70px;
  background-image: url(../images/register/sidepanel_bg_full.png);
  background-repeat: no-repeat;
  background-color: #167297;
  -webkit-background-size: 100% 100%;
  -moz-background-size: 100% 100%;
  -o-background-size: 100% 100%;
  background-size: 100% 100%;
  text-align: left;
  overflow-wrap: break-word;
}

.sidepanel-title {
font-family: 'ProximaBold-Condensed';
font-size: 45px;
margin-bottom: 0px !important;
}

.sidepanel-tagline {
font-family: 'Raleway', fantasy;
font-size: 38px;
margin-top: 0px !important;
}

.side-paddings {
margin-left: 14%;
margin-right: 13%;
}


.error-message-class {
margin-top: 23%;
font-family: 'Raleway';
color: red;
}


.country-colour{
color: rgb(126, 124, 124);
}


.account-creation-msg {
  font-size:x-large;
  background-color: rgb(217, 215, 215);
  color: black;
  border: 1.5px solid #d9e0e0;
  border-radius: 0.5%;

  padding: 8% !important;
  width: 70%;
  min-height: 100% !important;
  margin-top: 5% !important;
  margin-bottom: 5%;
  margin-left: 15%;

}

@media (max-width: 1400px){

.Page-register {                      
  display: flex ;
  min-height: 100%;
  margin-top: 10%;
  margin-bottom: 10%;
}

#sidepanel-col {
  display: none;
}

.register {
  font-size: 30px;
  margin-left: -18%;
  margin-top: -4%;
}

.register::after {
  margin-left: -8%;
  width: 115%;
  margin-top: 12%;
}



.reg-inputField {
  width: 90%;
  margin-top: 10%;
  font-size:14px;
}


.reg-inputField-address {
  width: 90%;
  margin-top: 10%;
  font-size:14px;
}


.check {
  margin-left: 2%;
  margin-top: 9%;
  margin-bottom: 5%;
}


.register-buttonField {
  width: 60%;
  height: 6%;
  margin-bottom: 7%;
  margin-left: 0.1%;
  font-size:14px;
}

.register-buttonField:hover {
  width: 60%;
  height: 6%;
  margin-bottom: 7%;
  margin-left: 0.1%;
  font-size:14px;
}



.error-message {
  margin-top: 23%;
  font-family: 'Raleway';
  color: red;
}

}