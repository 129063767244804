
 /* section 1 */
 .feedback-msg {
  font-size: x-large;
  background-color: rgb(216, 216, 216);
  color: black;
  border: 1.5px solid #d9e0e0;
  border-radius: 0%;
  font-family: 'Raleway';

  padding: 6% !important;
  width: 70%;
  min-height: 100% !important;
  margin-top: 5% !important;
  margin-bottom: 5%;
  margin-left: 15%;

}



.Page {                                                     /*  for all pages  */
    background-image: url(../images/cle_banner.png) ;         
    backdrop-filter: blur(10px) !important;
    display: flex ;
    align-items: center ;
    justify-content: center;
    min-height: 100%;
    height: 62dvh ;
    background-size: cover ;
    background-position: center ;
    
  }
  
    .banner-title                        
  {
      color: white;
      font-size: 310%;
      font-weight: bold;
  }
  
  
  .banner-tagline                    
  {
      font-size: 250%;
      color: white;
      font-family: 'Raleway', fantasy;
      margin-top: 0px !important;
  }
  
  @media (max-width: 1400px) {
    .banner-title {
        font-size: 10vw; 
    }
    .banner-tagline {
        font-size: 6vw; 
    }
    .feedback-msg {
      padding: 2%;
      width: 90%;
      margin-left: 5%;
      margin-right: 5%;

      font-size: large;
    }
}





/* section 2 */

  .services-container {
    padding-left: 15%;
    padding-right: 15%;
    padding-bottom: 2%;
    margin-bottom: 8%;
}

  .center-heading {
    font-family: 'Raleway';
    text-align: center !important;
    color: black ;
    font-size: 215%;
    margin-top: 5%;
    margin-bottom: 13% ; 
    display: inline-block;
}


.center-heading:after {
  content: "";
  display: block;
  bottom: 5%; /* adjust this value to control the distance of the line from the text */
  left: 44%;
  right: 44%;
  border-bottom: 2px solid rgb(47, 199, 238); /* change this to the color you want */
  padding-top: 20px;

  margin: 0 auto;
  /* width: 50%; */
  
}




.service-column {
  padding-left: 1% !important;
  padding-right: 1% !important;
  align-items: center;
  align-content: center;
}

.service-column:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 30%;
  padding-top: 8%;
  border-bottom: 2px solid #00e1ff;
}




.services_title_design:hover{
  text-decoration: underline;
  color: black;

}

.service-img {
  align-self: center;
  height: auto;
  width: 100%;
}

  
  .category_heading
  {
    font-family: 'Raleway-Medium';
    padding: 10%;
    margin-top: 12%;
    text-align: center;
    font-size: 45%;
    color: #000102;
  }

  .category_heading_title {
    font-size: 195%;
    text-transform: uppercase;
    color: black;
    text-decoration: none !important;
}

.category_heading:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 30%;
  padding-top: 20px;
  border-bottom: 2px solid #00e1ff ;
}
  


.service-box-text {
  padding: 5px;
  height: auto;
}


.paragraph_text {
  font-family: 'Raleway-Light';
  font-size: 14px;
  text-align: justify;
  text-justify: inter-word;
  /* text-align-last: justify; */
  text-decoration: none !important;
  color: black;
  padding: 1%;
}






/* section 3 */

.landing-container-3 {
  background: #0f8ea4;
  color: white !important;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 5%;
  font-family: 'Raleway';
}


.text-right {
  text-align: right;
}


.text-left {
  text-align: left;
}


.fellowship-heading-line {
  font-family: 'Raleway';
  text-align: center !important;
  font-size: 215%;
  margin-top: 5%;
  margin-bottom: 3% ; 
  display: inline-block;
}


.fellowship-heading-line::after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 50%;
  padding-top: 20px;
  border-bottom: 2px solid #69d4f8 ;
}




@media (min-width: 1200px){
.pic {
    width: 10%;
}
}

@media (min-width: 1200px){
.col-lg-10 {
    width: 90%;
    font-size: 90%;
}}



.get-started-column {
  align-items: flex-start;
  padding-top: 4%;
  padding-bottom: 4%;
}

.btn-cyan {
  color: #fff;
  border-radius: 0;
  border-color: #8ff0fd;
}

.btn-cyan:hover {
  color: #fff;
  border-radius: 0;
  border-color: #8ff0fd;
}





@media (max-width: 1400px){

  
  .pic {
    width: 10%;
    margin-left: 35%;
    margin-bottom: 15%;
} 

}










/* section 4 */

.landing-container-4 {
  background: #0f8ea4;
  color: white !important;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 5%;
  font-family: 'Raleway';
}

#get-started-container {
  background: #101010;
  color: white;
}


.get-started-heading-line {
  font-family: 'Raleway';
  text-align: center !important;
  color: rgb(253, 246, 246) ;
  font-size: 215%;
  margin-top: 5%;
  margin-bottom: 7% ; 
  display: inline-block;
  position: relative; /* make the parent element position:relative */
}


.get-started-heading-line::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -40%;
  left: 50%;
  transform: translateX(-50%);
  width: 70%;
  height: 2px;
  background-color: rgb(47, 199, 238);
}




.get-started-column {
  padding-top: 5%;
}

@media (min-width: 1200px){
.col-lg-offset-3 {
  margin-left: 25%;
}}

@media (min-width: 1200px){
.col-lg-6 {
  width: 50%;
}}


.get-started-column-text-heading {
  font-family: 'Raleway-Medium';
  font-size: 13px;
  text-align: justify;
  padding: 1%;
  padding-left: 10%;
}

.get-started-column-text {
  font-family: 'Raleway';
  font-size: 12px;
  text-align: justify;
  padding: 1%;
  padding-left: 10%;
  padding-right: 10%;
}

#get_started_tagline {
  font-family: 'Raleway';
  text-align: center;
  font-size: 2.5em;
}



@media (max-width: 1400px) {

  .landing-container-4{
    width: 100% !important;
    margin-bottom: 5%;
  }
  
  .get-started-column {
    margin-top: 2px;
    padding-top: 5%;
  }
  
  .get-started-icon {
    margin-left: 5% !important;
    margin-bottom: 5%;
  }
  
  
  .get-started-column-text {
    padding-left: 10%;
  }


}





/* section 5 */

.landing-container {
  background: #0f8ea4;
  color: white !important;
  padding-left: 15%;
  padding-right: 15%;
  padding-bottom: 5%;
  font-family: 'Raleway';
}

#contact {
  margin-bottom: 4%;
  background-color: white;
}


.contact-heading-line {
  font-family: 'Raleway';
  text-align: center !important;
  color: rgb(1, 1, 1) ;
  font-size: 215%;
  margin-top: 5%;
  margin-bottom: 1% ; 
  display: inline-block;
  position: relative; /* make the parent element position:relative */
}


.contact-heading-line:after {
  content: "";
  display: block;
  margin: 0 auto;
  width: 99%;
  padding-top: 20px;
  border-bottom: 2px solid #7ce4f6;
}




.contact-row {
  width: 100%;
  align-items: center;
  align-content: center;
}

.contact-column {
  align-items: flex-start;
  margin-top: 5vh;
  padding: 30px;
}


@media (min-width: 1200px){
.col-lg-4 {
  width: 33.33333333%;
}}

.contact-column-text {
  font-family: 'Raleway-Medium';
  font-size: 12px;
  padding: 1%;
  margin-top: 1vw;
  color: #000102;
}

#feedback-text {
  font-family: 'Raleway-Light';
  font-size: 14px;
  align-content: center;
  align-self: center;
  color: #000102;
}


@media (min-width: 1200px){
.col-lg-12 {
  width: 100%;
}}



.form-group {
  margin-bottom: 15px;
}


.contact-form-field {
  width: 100%;
  resize: none;
  border-radius: 0px !important;
  padding: 10px;
  border-top-width: 0px;
  border-left-width: 0px;
  border-right-width: 0px;
  border-bottom-width: 0px;
  background: linear-gradient(to right, #00e1ff 20%, #00e1ff 80%) 0 100% / 100% 2px no-repeat, linear-gradient(to right, transparent 30%,transparent 70%) 0 0 / 100% 2px no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 0 0 / 2px 100% no-repeat, linear-gradient(to bottom, transparent 80%, #00e1ff 20%) 100% 0 / 2px 100% no-repeat white;
}

button, input, select, textarea {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

@media (min-width: 1200px){
.col-lg-offset-6 {
    margin-left: 50%;
}}

@media (min-width: 1200px){
.col-lg-6 {
    width: 50%;
}}

@media (min-width: 1200px) {
.col-lg-1, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9 {
    float: left;
}}




#contact-button {
  color: black;
  border: solid #00e1ff thin;
  padding-left: 1em;
  padding-right: 1em;
  background: white;
  border-radius: 0;
  float: right;
}


@media (max-width: 1400px) {

  .landing-btn {
    margin-left: -16% !important;
  }

}