
.first-box-asr {
    margin-right: 8%;
    margin-left: 8%;
}

.left-services {
    font-family: 'Raleway';
    color: #337ab7;
    text-align: left;
    padding-top: 1%;
    font-weight: bold;
    font-size: 14px;
    margin-left: 3%;
}

.left-services:hover{
    text-decoration: underline;
}


.container-well {
    background-color: #eee !important;
    padding: 19px;
    border: 1px solid #e3e3e3;
    border-radius: 4px;
    box-shadow: inset 0 1px 1px rgba(0,0,0,.05);
}


.tts-heading {
    font-family: 'Raleway';
    font-size: 24px;
    padding-top: 2%;
    padding-bottom: 2%;
}

.flex-container-button {
    display: flex;
    flex-wrap: wrap;
}

#transcription {                     
    margin-left: 4%;
    margin-right: 2%;
    margin-bottom: 2%;
    margin-right: 2%;
    direction: rtl;

    width: 100% !important;
    height: 250px;
    font-size: 16px;
  }

  .btn-center {
    padding-left: 27%;
  }

.asr-button {
    font-family: 'Raleway';
    font-size: 90%;
    padding: 0.25em 2em !important;
    margin-right: 10px !important;
    margin-left: 15px !important;
    color: #000080 !important;
    background-color: transparent !important;
    border: 2px solid #000080 !important;
    border-radius: 10rem !important;
    transition: .2s !important;
}

.asr-button:disabled {
    opacity: 0.4;
  }


@media (max-width: 1400px){

    .first-box-asr {
        margin-left: 0%;
    }

    .middle-btn-asr{
        margin-left: -25%;
        margin-bottom: 4%;
      }


}
